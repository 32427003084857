import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TrackVisibility from 'react-on-screen';
import Sectiontitle from '../components/Sectiontitle';
import Layout from '../components/Layout';
import Progress from '../components/Progress';

function Skills() {
  const [fskills, setFskills] = useState([]);
  const [bskills, setBskills] = useState([]);
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    axios.get('/api/fskills').then((response) => {
      setFskills(response.data);
    });
    axios.get('/api/bskills').then((response) => {
      setBskills(response.data);
    });
    axios.get('/api/skills').then((response) => {
      setSkills(response.data);
    });
  }, []);

  return (
    <Layout>
      <div className="mi-skills-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="My Skills" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map((skill) => (
                <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div>
        <div className="mi-skills-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="My Frontend Skills" />
            <div className="mi-skills">
              <div className="row mt-30-reverse">
                {fskills.map((skill) => (
                  <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                    <Progress title={skill.title} percentage={skill.value} />
                  </TrackVisibility>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mi-skills-area mi-section mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="My Backend Skills" />
            <div className="mi-skills">
              <div className="row mt-30-reverse">
                {bskills.map((skill) => (
                  <TrackVisibility once className="col-lg-6 mt-30" key={skill.title}>
                    <Progress title={skill.title} percentage={skill.value} />
                  </TrackVisibility>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Skills;
